import React, {
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Slider } from 'antd';

import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import PauseOutlined from '@ant-design/icons/PauseOutlined';
import MutedIcon from 'shared/assets/icons/mute.svg';
import VolumeIcon from 'shared/assets/icons/volume.svg';
import useDeviceDetect from 'shared/lib/hooks/useDeviceDetect/useDeviceDetect';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Text } from 'shared/ui/text/Text';

import { useAudioControls } from '../hooks/useAudioControls';

import cls from './GlobalAudioChannel.module.scss';

interface IGlobalAudioChannelProps {
    isPinnedPost?: boolean;
}

export const GlobalAudioChannel: FC<IGlobalAudioChannelProps> = (props) => {
    const { isPinnedPost } = props;

    const [openVolume, setOpenVolume] = useState(false);
    const { isAndroid, isIOS } = useDeviceDetect();

    const holderContentRef = useRef<HTMLDivElement>(null);

    const {
        audioRef,
        closeAudioFile,
        date,
        fileData,
        handleAudioEnded,
        handleOnAudioClick,
        initAudioSettings,
        isPlaying,
        name,
        onChangeVolume,
        volume,
    } = useAudioControls();

    const { isTablet } = useScreenType();

    const handleToggleVolume = useCallback(() => {
        setOpenVolume((prev) => !prev);
    }, []);

    useEffect(() => {
        function handleDocumentClick(event: MouseEvent) {
            if (holderContentRef.current && !holderContentRef.current.contains(event.target as Node)) {
                setOpenVolume(false);
            }
        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);

    if (!fileData) {
        return null;
    }

    const elementName = (
        <div className={cls.holderName}>
            <Text text={name} className={cls.name} />
            {date && <Text text={date} className={cls.date} />}
        </div>
    );

    return (
        <div className={classNames(cls.container, { [cls.containerWithPinnedPost]: isPinnedPost })}>
            <audio
                controls={false}
                ref={audioRef}
                src={fileData.file}
                onLoadedData={initAudioSettings}
                onLoadedMetadata={initAudioSettings}
                onEnded={handleAudioEnded}
                preload="metadata"
            />

            <div className={cls.wrapperPlayBtn}>
                <button onClick={handleOnAudioClick} type="button" className={cls.playBtn}>
                    {isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                </button>
                {!isTablet && elementName}
            </div>

            {isTablet && elementName}

            <div className={cls.holderActionButton}>
                {!isIOS && !isAndroid && (
                    <div className={cls.holderVolume}>
                        {volume === 0 ? (
                            <MutedIcon onClick={handleToggleVolume} className={cls.mutedIcon} />
                        ) : (
                            <VolumeIcon onClick={handleToggleVolume} className={cls.mutedIcon} />
                        )}

                        {openVolume && (
                            <div className={cls.holderVolumeSlider} ref={holderContentRef}>
                                <Slider vertical reverse onChange={onChangeVolume} value={volume} />
                            </div>
                        )}
                    </div>
                )}

                <CloseOutlined onClick={closeAudioFile} className={cls.closeIcon} />
            </div>
        </div>
    );
};
