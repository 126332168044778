import {
    MouseEvent,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
    IFile,
    messagesActions,
    getPlayingFileHashId,
} from 'entities/message';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { getTimeMmSSFromNumber } from 'shared/lib/utils/date/date';

import {
    getAudioСhangedTime,
    getAudioFileIsPlaying,
    getAudioIsChangingWidthWave,
    getAudioIsEnded,
    getAudioTotalTime,
    getAudioWidthWave,
    getAudioFileData,
    getAudioName,
    getAudioMessageId,
    getAudioFileVolume,
    getAudioDate,
    getIsClearIntervalWidth,
} from '../model/selectors/global-audio-channel.selectors';
import { audioFileActions } from '../model/slice/global-audio-channel.slice';

interface IReturnAudioControls {
    audioRef: RefObject<HTMLAudioElement>;
    closeAudioFile: () => void;
    fileData: IFile | null;
    handleAudioEnded: () => void;
    handleOnAudioClick: (e: MouseEvent<HTMLButtonElement>) => void;
    initAudioSettings: () => void;
    isPlaying: boolean;
    name: string;
    volume: number;
    onChangeVolume: (volume: number) => void;
    date: string;
}

export const useAudioControls = (): IReturnAudioControls => {
    const dispatch = useAppDispatch();

    const audioRef = useRef<HTMLAudioElement>(null);

    const isEnded = useSelector(getAudioIsEnded);
    const totalTime = useSelector(getAudioTotalTime);
    const isPlaying = useSelector(getAudioFileIsPlaying);
    const playingFileId = useSelector(getPlayingFileHashId);
    const widthWave = useSelector(getAudioWidthWave);
    const isChangingWidthWave = useSelector(getAudioIsChangingWidthWave);
    const сhangedTime = useSelector(getAudioСhangedTime);
    const volume = useSelector(getAudioFileVolume);
    const fileData = useSelector(getAudioFileData);
    const name = useSelector(getAudioName);
    const messageId = useSelector(getAudioMessageId);
    const date = useSelector(getAudioDate);
    const isClearIntervalWidth = useSelector(getIsClearIntervalWidth);

    const [isLoading, setIsLoading] = useState(true);

    const hashFile = useMemo(() => {
        if (!fileData?.id) {
            return null;
        }

        if (!messageId) {
            return fileData?.id;
        }

        return `${messageId}/${fileData?.id}`;
    }, [fileData, messageId]);

    const onChangeVolume = useCallback((value: number) => {
        if (audioRef.current) {
            audioRef.current.volume = value / 100;
            dispatch(audioFileActions.setVolume(value));
        }
    }, []);

    const handleAudioEnded = useCallback(() => {
        dispatch(audioFileActions.togglePlayingAudio(false));
        dispatch(audioFileActions.setIsEnded(true));
        dispatch(audioFileActions.setWidthWave(0));
    }, []);

    const setIsPlaying = useCallback((value: boolean) => {
        dispatch(audioFileActions.togglePlayingAudio(value));
    }, []);

    const closeAudioFile = useCallback(() => {
        dispatch(audioFileActions.clearData());
    }, []);

    const onChangeTotalTime = useCallback((value: number) => {
        dispatch(audioFileActions.updateTotalTime(value));
    }, []);

    const onChangeIsEnded = useCallback((value: boolean) => {
        dispatch(audioFileActions.setIsEnded(value));
    }, []);

    const onChangeWidthWave = useCallback((value: number) => {
        dispatch(audioFileActions.setWidthWave(value));
    }, []);

    const onChangeTimeRemaining = useCallback((value: string) => {
        dispatch(audioFileActions.setTimeRemaining(value));
    }, []);

    const handleTimeUpdate = useCallback(() => {
        if (!audioRef.current) return;

        const durTime = audioRef.current.duration;
        const curTime = audioRef.current.currentTime;

        if (durTime) {
            onChangeTotalTime(durTime);
        }

        if (isEnded && curTime !== 0) {
            onChangeIsEnded(false);
        }

        if (curTime > durTime) {
            handleAudioEnded();
        }

        const timeRemaining = durTime - curTime;

        if (!timeRemaining) {
            onChangeTimeRemaining('');
            return;
        }

        onChangeTimeRemaining(getTimeMmSSFromNumber(timeRemaining || 0));
    }, [
        onChangeTotalTime,
        isEnded,
        onChangeIsEnded,
        handleAudioEnded,
        onChangeTimeRemaining,
    ]);

    const initAudioSettings = useCallback(() => {
        if (audioRef.current) {
            onChangeTotalTime(audioRef.current.duration);
        }

        handleTimeUpdate();
        setIsLoading(false);
        setIsPlaying(true);
        dispatch(messagesActions.updatePlayingFileHashId(hashFile));
    }, [hashFile]);

    const handleTogglePlayAudio = useCallback(() => {
        if (isLoading) {
            return;
        }

        if (isEnded && audioRef?.current?.paused) {
            onChangeIsEnded(false);
            setIsPlaying(true);
            return;
        }

        if (audioRef.current) {
            if (audioRef.current.paused) {
                setIsPlaying(true);
            } else {
                setIsPlaying(false);
            }
        }
    }, [audioRef, isLoading, isEnded]);

    const handleOnAudioClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            handleTogglePlayAudio();
        },
        [handleTogglePlayAudio],
    );

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, [totalTime]);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.play();
                handleTimeUpdate();
                dispatch(messagesActions.updatePlayingFileHashId(hashFile));
            } else {
                audioRef.current.pause();
            }

            audioRef.current.volume = volume / 100;
        }
    }, [isPlaying, isLoading, fileData]);

    useEffect(() => {
        const isStoppedAudio = playingFileId && playingFileId !== hashFile && audioRef.current && !audioRef.current.paused;

        if (isStoppedAudio) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    }, [playingFileId]);

    useEffect(() => {
        if (isEnded || !isPlaying || сhangedTime) return;
        let localWidth = widthWave;

        const intervalId = setInterval(() => {
            if (localWidth >= 100 || isClearIntervalWidth) {
                clearInterval(intervalId);
                dispatch(audioFileActions.setIsClearIntervalWidth(false));
                return;
            }

            const timeInMSeconds = totalTime * 1000;
            localWidth += (100 / (timeInMSeconds / 50)); // 50 - 50ms interval

            onChangeWidthWave(localWidth);
        }, 50);
        // eslint-disable-next-line consistent-return
        return () => clearInterval(intervalId);
    }, [
        isEnded,
        isPlaying,
        isChangingWidthWave,
        сhangedTime,
        totalTime,
        isClearIntervalWidth,
    ]);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (audioRef.current && сhangedTime) {
            audioRef.current.currentTime = сhangedTime;
            audioRef.current.play();
            setIsPlaying(true);
            dispatch(audioFileActions.setChangedTime(null));
        }
    }, [сhangedTime, isLoading, fileData]);

    return {
        audioRef,
        closeAudioFile,
        date,
        fileData,
        handleAudioEnded,
        handleOnAudioClick,
        initAudioSettings,
        isPlaying,
        name,
        onChangeVolume,
        volume,
    };
};
