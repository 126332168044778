import { FC } from 'react';

import { IFile } from 'entities/message';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { ImageWithLoader } from 'shared/ui/image-loader/ImageWithLoader';

import { IStaticStickerMessage } from '../../../model/types/message.types';
import {
    DEFAULT_STICKER_WIDTH,
    getStickersSizes,
} from '../../../utils/getStickersSizes';

import cls from './StaticSticker.module.scss';

interface IStaticStickerProps {
    className?: string;
    file: IFile;
    message: IStaticStickerMessage;
}

export const StaticSticker: FC<IStaticStickerProps> = (props) => {
    const { className, file, message } = props;

    const { height } = getStickersSizes(message);

    return (
        <div className={classNames(cls.staticSticker, {}, [className])}>
            <ImageWithLoader
                styles={{ height }}
                src={file.file}
                classNameSkeleton={cls.skeletonStaticSticker}
                alt="name"
                classNameImage={cls.imageStaticSticker}
                skeletonHeight={height}
                skeletonWidth={`${DEFAULT_STICKER_WIDTH}px`}
            />
        </div>
    );
};
