import {
    FC,
    Fragment,
    memo,
    useRef,
} from 'react';
import { useSelector } from 'react-redux';

import {
    IChannel,
    IVariable,
} from 'entities/channels';
import {
    Message,
    TMessage,
    messagesActions,
    getScrollMessageId,
} from 'entities/message';
import { IArgsContentViewer } from 'features/content-viewer';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { getDayjsWithTimezonePlugin } from 'shared/lib/utils/date/date';
import { Text } from 'shared/ui/text/Text';
import { checkSubsequentIndexNextDay } from 'widgets/message-list/utils/date';

import { usePinMessageAnimation } from '../../hooks/usePinMessageAnimation';
import cls from '../MessageList.module.scss';

interface IMessageRendererProps {
    channel?: IChannel;
    index: number;
    message: TMessage;
    messages: TMessage[];
    onEditMessage: (mes: TMessage) => void;
    openModalToRemoveMessage: (mesId: string, importedFrom: string | null) => void;
    variables: IVariable[];
    openPreviewVideoOrPhoto: (props: IArgsContentViewer) => void;
    showLastPublishedMessage: boolean;
    reloadMessages: Function;
    reloadChannels: Function;
}

export const MessageRenderer: FC<IMessageRendererProps> = memo((props) => {
    const {
        channel,
        index,
        message,
        messages,
        onEditMessage,
        openModalToRemoveMessage,
        openPreviewVideoOrPhoto,
        reloadChannels,
        reloadMessages,
        showLastPublishedMessage,
        variables,
    } = props;
    const scrollMessageId = useSelector(getScrollMessageId);

    const dispatch = useAppDispatch();

    const holderRef = useRef(null);
    const publishAt = getDayjsWithTimezonePlugin()(message.publishAt).tz(channel?.timezone);
    const isSubsequentIndexNextDay = checkSubsequentIndexNextDay(publishAt, index, messages, channel?.timezone);

    const clearPinMessageIndex = () => {
        dispatch(messagesActions.setScrollMessageId(null));
    };

    const isAnimationPinMessage = usePinMessageAnimation({
        holderRef,
        id: message.id,
        idPinMessage: scrollMessageId,
        setScrollMessageId: clearPinMessageIndex,
    });

    return (
        <Fragment key={message.id}>
            <div ref={holderRef} className={cls.holderMessage} id={message.id}>
                <Message
                    timezone={channel?.timezone}
                    onEditMessage={onEditMessage}
                    message={message}
                    openModalToRemoveMessage={openModalToRemoveMessage}
                    variables={variables}
                    openPreviewVideoOrPhoto={openPreviewVideoOrPhoto}
                    reloadMessages={reloadMessages}
                    reloadChannels={reloadChannels}
                />
                <div
                    className={classNames(cls.emptyBlock, {
                        [cls.animationPinMessage]: isAnimationPinMessage,
                    })}
                />
            </div>
            {showLastPublishedMessage && (
                <div className={classNames(
                    cls.lastPublishedMessage,
                    { [cls.lastPublishedSubsequentIndexNextDay]: isSubsequentIndexNextDay },
                )}
                >
                    <Text
                        text="Неопубликованные посты"
                        theme="gray"
                        className={cls.lastPublishedMessageTitle}
                        weight="600"
                        size="xs"
                    />
                </div>
            )}
        </Fragment>
    );
});
