import {
    FC,
    SVGProps,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './WrappedIcon.module.scss';

export type IIconTheme = 'primary' | 'gray' | 'black-45' | 'error' | 'blue' | 'white' | 'black-88' | 'black';
export type IIconSize = '24' | '20' | '18' | '16' | '14';

interface IWrappedIconProps extends SVGProps<SVGElement> {
    className?: string;
    Icon: any;
    theme?: IIconTheme;
    disabled?: boolean;
    size?: IIconSize;
    cursorPointer?: boolean;
}

export const WrappedIcon: FC<IWrappedIconProps> = (props) => {
    const {
        Icon, className, cursorPointer, disabled,
        size, theme = 'primary', ...otherProps
    } = props;

    const sizeClass = cls[`size${size}`];

    return (
        <Icon
            className={classNames(
                cls.WrappedIcon,
                {
                    [cls.disabled]: disabled,
                    [sizeClass]: size,
                    [cls.cursorPointer]: cursorPointer,
                },
                [className, cls[theme]],
            )}
            {...otherProps}
        />
    );
};
