import { IStateSchema } from 'app/providers/store';

export const getEditMessageDate = (state: IStateSchema) => state.editMessage.date;
export const getEditMessageDocuments = (state: IStateSchema) => state.editMessage.documents;
export const getEditMessageMedia = (state: IStateSchema) => state.editMessage.media;
export const getEditMessageText = (state: IStateSchema) => state.editMessage.text;
export const getEditMessageTime = (state: IStateSchema) => state.editMessage.time;
export const getEditMessageData = (state: IStateSchema) => state.editMessage.message;
export const getEditMessageTimezone = (state: IStateSchema) => state.editMessage.timezone;
export const getEditMessageVariables = (state: IStateSchema) => state.editMessage.variables;
export const getEditMessagePostType = (state: IStateSchema) => state.editMessage.postType;
export const getEditMessageButtons = (state: IStateSchema) => state.editMessage.buttons;
export const getEditMessageMaxSymbolsLength = (state: IStateSchema) => state.editMessage.maxSymbolsLength;
export const getEditMessageIsNotifyPin = (state: IStateSchema) => state.editMessage.isNotifyPin;
export const getEditMessagePinDuration = (state: IStateSchema) => state.editMessage.pinDuration;
export const getEditMessageIsPinIndefinitely = (state: IStateSchema) => state.editMessage.isPinIndefinitely;
export const getEditMessageIsDirty = (state: IStateSchema) => state.editMessage.isDirty;
export const getEditMessageRoundVideo = (state: IStateSchema) => state.editMessage.roundVideo;
export const getEditMessageVoice = (state: IStateSchema) => state.editMessage.voice;
export const getEditMessageAudio = (state: IStateSchema) => state.editMessage.audio;
export const getEditMessageDateValidType = (state: IStateSchema) => state.editMessage.dateValidType;
export const getEditMessageIsWebPreview = (state: IStateSchema) => state.editMessage.isWebPreview;
