import {
    Dispatch,
    FC,
    SetStateAction,
    memo,
} from 'react';

import { Button } from 'antd';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { getDayjsWithTimezonePlugin } from 'shared/lib/utils/date/date';
import { Text } from 'shared/ui/text/Text';

import {
    EPublishStatus,
    TMessage,
} from '../../model/types/message.types';
import { ResendButton } from '../resend-button/ResendButton';

import cls from './ControlsMessage.module.scss';

interface IControlsMessageProps {
    onEditMessage: (mes: TMessage) => void;
    handleToggleModalOnDelete: () => void;
    message: TMessage;
    timezone?: string;
    className?: string;
    handleCancelPopover: () => void;
    setIsResend: Dispatch<SetStateAction<boolean>>;
}

export const ControlsMessage: FC<IControlsMessageProps> = memo(
    (props) => {
        const {
            className,
            handleCancelPopover,
            handleToggleModalOnDelete,
            message,
            onEditMessage,
            setIsResend,
            timezone,
        } = props;

        const isPublishStatusError = message.postPublishStatus === EPublishStatus.ERROR;

        const handleEditMessage = () => {
            onEditMessage({
                ...message,
                publishAt: getDayjsWithTimezonePlugin()(message.publishAt).tz(timezone),
            });
            handleCancelPopover();
        };

        const onToggleModalOnDelete = () => {
            handleToggleModalOnDelete();
            handleCancelPopover();
        };

        return (
            <div className={classNames(cls.container, {}, [className])}>
                {isPublishStatusError && (
                    <ResendButton
                        handleCancelPopover={handleCancelPopover}
                        setIsResend={setIsResend}
                    />
                )}
                <Button type="text" onClick={handleEditMessage} className={cls.button}>
                    <Text text="Редактировать" theme="black" />
                    <FormOutlined className={cls.icon} />
                </Button>
                <Button type="text" onClick={onToggleModalOnDelete} className={cls.button} danger>
                    <Text text="Удалить" theme="error" />
                    <DeleteOutlined className={cls.icon} />
                </Button>
            </div>
        );
    },
);
