import { getDayjsWithTimezonePlugin } from 'shared/lib/utils/date/date';

export interface ITimezone {
    code: string;
    name: string;
}

const timezones: ITimezone[] = [
    { code: 'Africa/Abidjan', name: 'Абиджан' },
    { code: 'Africa/Accra', name: 'Аккра' },
    { code: 'Africa/Addis_Ababa', name: 'Аддис-Абеба' },
    { code: 'Africa/Algiers', name: 'Алжир' },
    { code: 'Africa/Asmera', name: 'Асмэра' },
    { code: 'Africa/Bamako', name: 'Бамако' },
    { code: 'Africa/Bangui', name: 'Банги' },
    { code: 'Africa/Banjul', name: 'Банжул' },
    { code: 'Africa/Bissau', name: 'Бисау' },
    { code: 'Africa/Blantyre', name: 'Блантайр' },
    { code: 'Africa/Brazzaville', name: 'Браззавиль' },
    { code: 'Africa/Bujumbura', name: 'Бужумбура' },
    { code: 'Africa/Cairo', name: 'Каир' },
    { code: 'Africa/Casablanca', name: 'Касабланка' },
    { code: 'Africa/Ceuta', name: 'Сеута' },
    { code: 'Africa/Conakry', name: 'Конакри' },
    { code: 'Africa/Dakar', name: 'Дакар' },
    { code: 'Africa/Dar_es_Salaam', name: 'Дар-эс-Салам' },
    { code: 'Africa/Djibouti', name: 'Джибути' },
    { code: 'Africa/Douala', name: 'Дуала' },
    { code: 'Africa/El_Aaiun', name: 'Эль-Аюн' },
    { code: 'Africa/Freetown', name: 'Фритаун' },
    { code: 'Africa/Gaborone', name: 'Габороне' },
    { code: 'Africa/Harare', name: 'Хараре' },
    { code: 'Africa/Johannesburg', name: 'Йоханнесбург' },
    { code: 'Africa/Juba', name: 'Джуба' },
    { code: 'Africa/Kampala', name: 'Кампала' },
    { code: 'Africa/Khartoum', name: 'Хартум' },
    { code: 'Africa/Kigali', name: 'Кигали' },
    { code: 'Africa/Kinshasa', name: 'Киншаса' },
    { code: 'Africa/Lagos', name: 'Лагос' },
    { code: 'Africa/Libreville', name: 'Либревиль' },
    { code: 'Africa/Lome', name: 'Ломе' },
    { code: 'Africa/Luanda', name: 'Луанда' },
    { code: 'Africa/Lubumbashi', name: 'Лубумбаши' },
    { code: 'Africa/Lusaka', name: 'Лусака' },
    { code: 'Africa/Malabo', name: 'Малабо' },
    { code: 'Africa/Maputo', name: 'Мапуту' },
    { code: 'Africa/Maseru', name: 'Масеру' },
    { code: 'Africa/Mbabane', name: 'Мбабане' },
    { code: 'Africa/Mogadishu', name: 'Могадишо' },
    { code: 'Africa/Monrovia', name: 'Монровия' },
    { code: 'Africa/Nairobi', name: 'Найроби' },
    { code: 'Africa/Ndjamena', name: 'Нджамена' },
    { code: 'Africa/Niamey', name: 'Ниамей' },
    { code: 'Africa/Nouakchott', name: 'Нуакшот' },
    { code: 'Africa/Ouagadougou', name: 'Уагадугу' },
    { code: 'Africa/Porto-Novo', name: 'Порто-Ново' },
    { code: 'Africa/Sao_Tome', name: 'Сан-Томе' },
    { code: 'Africa/Tripoli', name: 'Триполи' },
    { code: 'Africa/Tunis', name: 'Тунис' },
    { code: 'Africa/Windhoek', name: 'Виндхук' },
    { code: 'America/Adak', name: 'Адак' },
    { code: 'America/Anchorage', name: 'Анкоридж' },
    { code: 'America/Anguilla', name: 'Ангилья' },
    { code: 'America/Antigua', name: 'Антигуа' },
    { code: 'America/Araguaina', name: 'Арагуаина' },
    { code: 'America/Argentina/La_Rioja', name: 'Ла-Риоха' },
    { code: 'America/Argentina/Rio_Gallegos', name: 'Рио-Гальегос' },
    { code: 'America/Argentina/Salta', name: 'Сальта' },
    { code: 'America/Argentina/San_Juan', name: 'Сан-Хуан' },
    { code: 'America/Argentina/San_Luis', name: 'Сан-Луис' },
    { code: 'America/Argentina/Tucuman', name: 'Тукуман' },
    { code: 'America/Argentina/Ushuaia', name: 'Ушуая' },
    { code: 'America/Aruba', name: 'Аруба' },
    { code: 'America/Asuncion', name: 'Асунсьон' },
    { code: 'America/Bahia', name: 'Баия' },
    { code: 'America/Bahia_Banderas', name: 'Баия-де-Бандерас' },
    { code: 'America/Barbados', name: 'Барбадос' },
    { code: 'America/Belem', name: 'Белен' },
    { code: 'America/Belize', name: 'Белиз' },
    { code: 'America/Blanc-Sablon', name: 'Бланк-Саблон' },
    { code: 'America/Boa_Vista', name: 'Боа-Виста' },
    { code: 'America/Bogota', name: 'Богота' },
    { code: 'America/Boise', name: 'Бойсе' },
    { code: 'America/Buenos_Aires', name: 'Буэнос-Айрес' },
    { code: 'America/Cambridge_Bay', name: 'Кеймбридж-Бей' },
    { code: 'America/Campo_Grande', name: 'Кампу-Гранди' },
    { code: 'America/Cancun', name: 'Канкун' },
    { code: 'America/Caracas', name: 'Каракас' },
    { code: 'America/Catamarca', name: 'Катамарка' },
    { code: 'America/Cayenne', name: 'Кайенна' },
    { code: 'America/Cayman', name: 'Острова Кайман' },
    { code: 'America/Chicago', name: 'Чикаго' },
    { code: 'America/Chihuahua', name: 'Чиуауа' },
    { code: 'America/Coral_Harbour', name: 'Корал-Харбор' },
    { code: 'America/Cordoba', name: 'Кордова' },
    { code: 'America/Costa_Rica', name: 'Коста-Рика' },
    { code: 'America/Creston', name: 'Крестон' },
    { code: 'America/Cuiaba', name: 'Куяба' },
    { code: 'America/Curacao', name: 'Кюрасао' },
    { code: 'America/Danmarkshavn', name: 'Денмарксхавн' },
    { code: 'America/Dawson', name: 'Доусон' },
    { code: 'America/Dawson_Creek', name: 'Доусон-Крик' },
    { code: 'America/Denver', name: 'Денвер' },
    { code: 'America/Detroit', name: 'Детройт' },
    { code: 'America/Dominica', name: 'Доминика' },
    { code: 'America/Edmonton', name: 'Эдмонтон' },
    { code: 'America/Eirunepe', name: 'Эйрунепе' },
    { code: 'America/El_Salvador', name: 'Сальвадор' },
    { code: 'America/Fort_Nelson', name: 'Форт Нельсон' },
    { code: 'America/Fortaleza', name: 'Форталеза' },
    { code: 'America/Glace_Bay', name: 'Глейс-Бей' },
    { code: 'America/Godthab', name: 'Нуук' },
    { code: 'America/Goose_Bay', name: 'Гус-Бей' },
    { code: 'America/Grand_Turk', name: 'Гранд-Терк' },
    { code: 'America/Grenada', name: 'Гренада' },
    { code: 'America/Guadeloupe', name: 'Гваделупа' },
    { code: 'America/Guatemala', name: 'Гватемала' },
    { code: 'America/Guayaquil', name: 'Гуаякиль' },
    { code: 'America/Guyana', name: 'Гайана' },
    { code: 'America/Halifax', name: 'Галифакс' },
    { code: 'America/Havana', name: 'Гавана' },
    { code: 'America/Hermosillo', name: 'Эрмосильо' },
    { code: 'America/Indiana/Knox', name: 'Нокс, Индиана' },
    { code: 'America/Indiana/Marengo', name: 'Маренго, Индиана' },
    { code: 'America/Indiana/Petersburg', name: 'Питерсберг, Индиана' },
    { code: 'America/Indiana/Tell_City', name: 'Телл-Сити' },
    { code: 'America/Indiana/Vevay', name: 'Вевей, Индиана' },
    { code: 'America/Indiana/Vincennes', name: 'Винсеннес' },
    { code: 'America/Indiana/Winamac', name: 'Уинамак' },
    { code: 'America/Indianapolis', name: 'Индианаполис' },
    { code: 'America/Inuvik', name: 'Инувик' },
    { code: 'America/Iqaluit', name: 'Икалуит' },
    { code: 'America/Jamaica', name: 'Ямайка' },
    { code: 'America/Jujuy', name: 'Жужуй' },
    { code: 'America/Juneau', name: 'Джуно' },
    { code: 'America/Kentucky/Monticello', name: 'Монтиселло, Кентукки' },
    { code: 'America/Kralendijk', name: 'Кралендейк' },
    { code: 'America/La_Paz', name: 'Ла-Пас' },
    { code: 'America/Lima', name: 'Лима' },
    { code: 'America/Los_Angeles', name: 'Лос-Анджелес' },
    { code: 'America/Louisville', name: 'Луисвилл' },
    { code: 'America/Lower_Princes', name: 'Лоуэр-Принс-Куотер' },
    { code: 'America/Maceio', name: 'Масейо' },
    { code: 'America/Managua', name: 'Манагуа' },
    { code: 'America/Manaus', name: 'Манаус' },
    { code: 'America/Marigot', name: 'Мариго' },
    { code: 'America/Martinique', name: 'Мартиника' },
    { code: 'America/Matamoros', name: 'Матаморос' },
    { code: 'America/Mazatlan', name: 'Масатлан' },
    { code: 'America/Mendoza', name: 'Мендоса' },
    { code: 'America/Menominee', name: 'Меномини' },
    { code: 'America/Merida', name: 'Мерида' },
    { code: 'America/Metlakatla', name: 'Метлакатла' },
    { code: 'America/Mexico_City', name: 'Мехико' },
    { code: 'America/Miquelon', name: 'Сен-Пьер и Микелон' },
    { code: 'America/Moncton', name: 'Монктон' },
    { code: 'America/Monterrey', name: 'Монтеррей' },
    { code: 'America/Montevideo', name: 'Монтевидео' },
    { code: 'America/Montserrat', name: 'Монтсеррат' },
    { code: 'America/Nassau', name: 'Нассау' },
    { code: 'America/New_York', name: 'Нью-Йорк' },
    { code: 'America/Nipigon', name: 'Нипигон' },
    { code: 'America/Nome', name: 'Ном' },
    { code: 'America/Noronha', name: 'Фернанду-ди-Норонья' },
    { code: 'America/North_Dakota/Beulah', name: 'Бойла, Северная Дакота' },
    { code: 'America/North_Dakota/Center', name: 'Центр, Северная Дакота' },
    { code: 'America/North_Dakota/New_Salem', name: 'Нью-Сейлем, Северная Дакота' },
    { code: 'America/Ojinaga', name: 'Охинага' },
    { code: 'America/Panama', name: 'Панама' },
    { code: 'America/Pangnirtung', name: 'Пангниртанг' },
    { code: 'America/Paramaribo', name: 'Парамарибо' },
    { code: 'America/Phoenix', name: 'Финикс' },
    { code: 'America/Port-au-Prince', name: 'Порт-о-Пренс' },
    { code: 'America/Port_of_Spain', name: 'Порт-оф-Спейн' },
    { code: 'America/Porto_Velho', name: 'Порту-Велью' },
    { code: 'America/Puerto_Rico', name: 'Пуэрто-Рико' },
    { code: 'America/Punta_Arenas', name: 'Пунта-Аренас' },
    { code: 'America/Rainy_River', name: 'Рейни-Ривер' },
    { code: 'America/Rankin_Inlet', name: 'Ранкин-Инлет' },
    { code: 'America/Recife', name: 'Ресифи' },
    { code: 'America/Regina', name: 'Реджайна' },
    { code: 'America/Resolute', name: 'Резольют' },
    { code: 'America/Rio_Branco', name: 'Риу-Бранку' },
    { code: 'America/Santa_Isabel', name: 'Санта-Изабел' },
    { code: 'America/Santarem', name: 'Сантарен' },
    { code: 'America/Santiago', name: 'Сантьяго' },
    { code: 'America/Santo_Domingo', name: 'Санто-Доминго' },
    { code: 'America/Sao_Paulo', name: 'Сан-Паулу' },
    { code: 'America/Scoresbysund', name: 'Скорсбисунн' },
    { code: 'America/Sitka', name: 'Ситка' },
    { code: 'America/St_Barthelemy', name: 'Сен-Бартелеми' },
    { code: 'America/St_Johns', name: 'Сент-Джонс' },
    { code: 'America/St_Kitts', name: 'Сент-Китс' },
    { code: 'America/St_Lucia', name: 'Сент-Люсия' },
    { code: 'America/St_Thomas', name: 'Сент-Томас' },
    { code: 'America/St_Vincent', name: 'Сент-Винсент' },
    { code: 'America/Swift_Current', name: 'Свифт-Керрент' },
    { code: 'America/Tegucigalpa', name: 'Тегусигальпа' },
    { code: 'America/Thule', name: 'Туле' },
    { code: 'America/Thunder_Bay', name: 'Тандер-Бей' },
    { code: 'America/Tijuana', name: 'Тихуана' },
    { code: 'America/Toronto', name: 'Торонто' },
    { code: 'America/Tortola', name: 'Тортола' },
    { code: 'America/Vancouver', name: 'Ванкувер' },
    { code: 'America/Whitehorse', name: 'Уайтхорс' },
    { code: 'America/Winnipeg', name: 'Виннипег' },
    { code: 'America/Yakutat', name: 'Якутат' },
    { code: 'America/Yellowknife', name: 'Йеллоунайф' },
    { code: 'Antarctica/Casey', name: 'Кейси' },
    { code: 'Antarctica/Davis', name: 'Дейвис' },
    { code: 'Antarctica/DumontDUrville', name: 'Дюмон-д’Юрвиль' },
    { code: 'Antarctica/Macquarie', name: 'Маккуори' },
    { code: 'Antarctica/Mawson', name: 'Моусон' },
    { code: 'Antarctica/McMurdo', name: 'Мак-Мердо' },
    { code: 'Antarctica/Palmer', name: 'Палмер' },
    { code: 'Antarctica/Rothera', name: 'Ротера' },
    { code: 'Antarctica/Syowa', name: 'Сёва' },
    { code: 'Antarctica/Troll', name: 'Тролль' },
    { code: 'Antarctica/Vostok', name: 'Восток' },
    { code: 'Arctic/Longyearbyen', name: 'Лонгйир' },
    { code: 'Asia/Aden', name: 'Аден' },
    { code: 'Asia/Almaty', name: 'Алматы' },
    { code: 'Asia/Amman', name: 'Амман' },
    { code: 'Asia/Anadyr', name: 'Анадырь' },
    { code: 'Asia/Aqtau', name: 'Актау' },
    { code: 'Asia/Aqtobe', name: 'Актобе' },
    { code: 'Asia/Ashgabat', name: 'Ашхабад' },
    { code: 'Asia/Atyrau', name: 'Атырау' },
    { code: 'Asia/Baghdad', name: 'Багдад' },
    { code: 'Asia/Bahrain', name: 'Бахрейн' },
    { code: 'Asia/Baku', name: 'Баку' },
    { code: 'Asia/Bangkok', name: 'Бангкок' },
    { code: 'Asia/Barnaul', name: 'Барнаул' },
    { code: 'Asia/Beirut', name: 'Бейрут' },
    { code: 'Asia/Bishkek', name: 'Бишкек' },
    { code: 'Asia/Brunei', name: 'Бруней-Даруссалам' },
    { code: 'Asia/Calcutta', name: 'Калькутта' },
    { code: 'Asia/Chita', name: 'Чита' },
    { code: 'Asia/Choibalsan', name: 'Чойбалсан' },
    { code: 'Asia/Colombo', name: 'Коломбо' },
    { code: 'Asia/Damascus', name: 'Дамаск' },
    { code: 'Asia/Dhaka', name: 'Дакка' },
    { code: 'Asia/Dili', name: 'Дили' },
    { code: 'Asia/Dubai', name: 'Дубай' },
    { code: 'Asia/Dushanbe', name: 'Душанбе' },
    { code: 'Asia/Famagusta', name: 'Фамагуста' },
    { code: 'Asia/Gaza', name: 'Газа' },
    { code: 'Asia/Hebron', name: 'Хеврон' },
    { code: 'Asia/Hong_Kong', name: 'Гонконг' },
    { code: 'Asia/Hovd', name: 'Ховд' },
    { code: 'Asia/Irkutsk', name: 'Иркутск' },
    { code: 'Asia/Jakarta', name: 'Джакарта' },
    { code: 'Asia/Jayapura', name: 'Джаяпура' },
    { code: 'Asia/Jerusalem', name: 'Иерусалим' },
    { code: 'Asia/Kabul', name: 'Кабул' },
    { code: 'Asia/Kamchatka', name: 'Петропавловск-Камчатский' },
    { code: 'Asia/Karachi', name: 'Карачи' },
    { code: 'Asia/Katmandu', name: 'Катманду' },
    { code: 'Asia/Khandyga', name: 'Хандыга' },
    { code: 'Asia/Krasnoyarsk', name: 'Красноярск' },
    { code: 'Asia/Kuala_Lumpur', name: 'Куала-Лумпур' },
    { code: 'Asia/Kuching', name: 'Кучинг' },
    { code: 'Asia/Kuwait', name: 'Кувейт' },
    { code: 'Asia/Macau', name: 'Макао' },
    { code: 'Asia/Magadan', name: 'Магадан' },
    { code: 'Asia/Makassar', name: 'Макасар' },
    { code: 'Asia/Manila', name: 'Манила' },
    { code: 'Asia/Muscat', name: 'Маскат' },
    { code: 'Asia/Nicosia', name: 'Никосия' },
    { code: 'Asia/Novokuznetsk', name: 'Новокузнецк' },
    { code: 'Asia/Novosibirsk', name: 'Новосибирск' },
    { code: 'Asia/Omsk', name: 'Омск' },
    { code: 'Asia/Oral', name: 'Уральск' },
    { code: 'Asia/Phnom_Penh', name: 'Пномпень' },
    { code: 'Asia/Pontianak', name: 'Понтианак' },
    { code: 'Asia/Pyongyang', name: 'Пхеньян' },
    { code: 'Asia/Qatar', name: 'Катар' },
    { code: 'Asia/Qostanay', name: 'Костанай' },
    { code: 'Asia/Qyzylorda', name: 'Кызылорда' },
    { code: 'Asia/Rangoon', name: 'Янгон' },
    { code: 'Asia/Riyadh', name: 'Эр-Рияд' },
    { code: 'Asia/Saigon', name: 'Хошимин' },
    { code: 'Asia/Sakhalin', name: 'о-в Сахалин' },
    { code: 'Asia/Samarkand', name: 'Самарканд' },
    { code: 'Asia/Seoul', name: 'Сеул' },
    { code: 'Asia/Shanghai', name: 'Шанхай' },
    { code: 'Asia/Singapore', name: 'Сингапур' },
    { code: 'Asia/Srednekolymsk', name: 'Среднеколымск' },
    { code: 'Asia/Taipei', name: 'Тайбэй' },
    { code: 'Asia/Tashkent', name: 'Ташкент' },
    { code: 'Asia/Tbilisi', name: 'Тбилиси' },
    { code: 'Asia/Tehran', name: 'Тегеран' },
    { code: 'Asia/Thimphu', name: 'Тхимпху' },
    { code: 'Asia/Tokyo', name: 'Токио' },
    { code: 'Asia/Tomsk', name: 'Томск' },
    { code: 'Asia/Ulaanbaatar', name: 'Улан-Батор' },
    { code: 'Asia/Urumqi', name: 'Урумчи' },
    { code: 'Asia/Ust-Nera', name: 'Усть-Нера' },
    { code: 'Asia/Vientiane', name: 'Вьентьян' },
    { code: 'Asia/Vladivostok', name: 'Владивосток' },
    { code: 'Asia/Yakutsk', name: 'Якутск' },
    { code: 'Asia/Yekaterinburg', name: 'Екатеринбург' },
    { code: 'Asia/Yerevan', name: 'Ереван' },
    { code: 'Atlantic/Azores', name: 'Азорские о-ва' },
    { code: 'Atlantic/Bermuda', name: 'Бермудские о-ва' },
    { code: 'Atlantic/Canary', name: 'Канарские о-ва' },
    { code: 'Atlantic/Cape_Verde', name: 'Кабо-Верде' },
    { code: 'Atlantic/Faeroe', name: 'Фарерские о-ва' },
    { code: 'Atlantic/Madeira', name: 'Мадейра' },
    { code: 'Atlantic/Reykjavik', name: 'Рейкьявик' },
    { code: 'Atlantic/South_Georgia', name: 'Южная Георгия' },
    { code: 'Atlantic/St_Helena', name: 'о-в Святой Елены' },
    { code: 'Atlantic/Stanley', name: 'Стэнли' },
    { code: 'Australia/Adelaide', name: 'Аделаида' },
    { code: 'Australia/Brisbane', name: 'Брисбен' },
    { code: 'Australia/Broken_Hill', name: 'Брокен-Хилл' },
    { code: 'Australia/Currie', name: 'Керри' },
    { code: 'Australia/Darwin', name: 'Дарвин' },
    { code: 'Australia/Eucla', name: 'Юкла' },
    { code: 'Australia/Hobart', name: 'Хобарт' },
    { code: 'Australia/Lindeman', name: 'Линдеман' },
    { code: 'Australia/Lord_Howe', name: 'Лорд-Хау' },
    { code: 'Australia/Melbourne', name: 'Мельбурн' },
    { code: 'Australia/Perth', name: 'Перт' },
    { code: 'Australia/Sydney', name: 'Сидней' },
    { code: 'CST6CDT', name: 'Центральная Америка' },
    { code: 'EST5EDT', name: 'Восточная Америка' },
    { code: 'Etc/GMT', name: 'Среднее время по Гринвичу' },
    { code: 'Etc/UTC', name: 'Всемирное координированное время' },
    { code: 'Europe/Amsterdam', name: 'Амстердам' },
    { code: 'Europe/Andorra', name: 'Андорра' },
    { code: 'Europe/Astrakhan', name: 'Астрахань' },
    { code: 'Europe/Athens', name: 'Афины' },
    { code: 'Europe/Belgrade', name: 'Белград' },
    { code: 'Europe/Berlin', name: 'Берлин' },
    { code: 'Europe/Bratislava', name: 'Братислава' },
    { code: 'Europe/Brussels', name: 'Брюссель' },
    { code: 'Europe/Bucharest', name: 'Бухарест' },
    { code: 'Europe/Budapest', name: 'Будапешт' },
    { code: 'Europe/Busingen', name: 'Бюзинген-на-Верхнем-Рейне' },
    { code: 'Europe/Chisinau', name: 'Кишинев' },
    { code: 'Europe/Copenhagen', name: 'Копенгаген' },
    { code: 'Europe/Dublin', name: 'Дублин' },
    { code: 'Europe/Gibraltar', name: 'Гибралтар' },
    { code: 'Europe/Guernsey', name: 'Гернси' },
    { code: 'Europe/Helsinki', name: 'Хельсинки' },
    { code: 'Europe/Isle_of_Man', name: 'о-в Мэн' },
    { code: 'Europe/Istanbul', name: 'Стамбул' },
    { code: 'Europe/Jersey', name: 'Джерси' },
    { code: 'Europe/Kaliningrad', name: 'Калининград' },
    { code: 'Europe/Kiev', name: 'Киев' },
    { code: 'Europe/Kirov', name: 'Киров' },
    { code: 'Europe/Lisbon', name: 'Лиссабон' },
    { code: 'Europe/Ljubljana', name: 'Любляна' },
    { code: 'Europe/London', name: 'Лондон' },
    { code: 'Europe/Luxembourg', name: 'Люксембург' },
    { code: 'Europe/Madrid', name: 'Мадрид' },
    { code: 'Europe/Malta', name: 'Мальта' },
    { code: 'Europe/Mariehamn', name: 'Мариехамн' },
    { code: 'Europe/Minsk', name: 'Минск' },
    { code: 'Europe/Monaco', name: 'Монако' },
    { code: 'Europe/Moscow', name: 'Москва' },
    { code: 'Europe/Oslo', name: 'Осло' },
    { code: 'Europe/Paris', name: 'Париж' },
    { code: 'Europe/Podgorica', name: 'Подгорица' },
    { code: 'Europe/Prague', name: 'Прага' },
    { code: 'Europe/Riga', name: 'Рига' },
    { code: 'Europe/Rome', name: 'Рим' },
    { code: 'Europe/Samara', name: 'Самара' },
    { code: 'Europe/San_Marino', name: 'Сан-Марино' },
    { code: 'Europe/Sarajevo', name: 'Сараево' },
    { code: 'Europe/Saratov', name: 'Саратов' },
    { code: 'Europe/Simferopol', name: 'Симферополь' },
    { code: 'Europe/Skopje', name: 'Скопье' },
    { code: 'Europe/Sofia', name: 'София' },
    { code: 'Europe/Stockholm', name: 'Стокгольм' },
    { code: 'Europe/Tallinn', name: 'Таллин' },
    { code: 'Europe/Tirane', name: 'Тирана' },
    { code: 'Europe/Ulyanovsk', name: 'Ульяновск' },
    { code: 'Europe/Uzhgorod', name: 'Ужгород' },
    { code: 'Europe/Vaduz', name: 'Вадуц' },
    { code: 'Europe/Vatican', name: 'Ватикан' },
    { code: 'Europe/Vienna', name: 'Вена' },
    { code: 'Europe/Vilnius', name: 'Вильнюс' },
    { code: 'Europe/Volgograd', name: 'Волгоград' },
    { code: 'Europe/Warsaw', name: 'Варшава' },
    { code: 'Europe/Zagreb', name: 'Загреб' },
    { code: 'Europe/Zaporozhye', name: 'Запорожье' },
    { code: 'Europe/Zurich', name: 'Цюрих' },
    { code: 'Indian/Antananarivo', name: 'Антананариву' },
    { code: 'Indian/Chagos', name: 'Чагос' },
    { code: 'Indian/Christmas', name: 'о-в Рождества' },
    { code: 'Indian/Cocos', name: 'Кокосовые о-ва' },
    { code: 'Indian/Comoro', name: 'Коморы' },
    { code: 'Indian/Kerguelen', name: 'Кергелен' },
    { code: 'Indian/Mahe', name: 'Маэ' },
    { code: 'Indian/Maldives', name: 'Мальдивы' },
    { code: 'Indian/Mauritius', name: 'Маврикий' },
    { code: 'Indian/Mayotte', name: 'Майотта' },
    { code: 'Indian/Reunion', name: 'Реюньон' },
    { code: 'MST7MDT', name: 'Северная Америка' },
    { code: 'PST8PDT', name: 'Тихоокеанское время' },
    { code: 'Pacific/Apia', name: 'Апиа' },
    { code: 'Pacific/Auckland', name: 'Окленд' },
    { code: 'Pacific/Bougainville', name: 'Бугенвиль' },
    { code: 'Pacific/Chatham', name: 'Чатем' },
    { code: 'Pacific/Easter', name: 'О-в Пасхи' },
    { code: 'Pacific/Efate', name: 'Эфате' },
    { code: 'Pacific/Enderbury', name: 'о-в Эндербери' },
    { code: 'Pacific/Fakaofo', name: 'Факаофо' },
    { code: 'Pacific/Fiji', name: 'Фиджи' },
    { code: 'Pacific/Funafuti', name: 'Фунафути' },
    { code: 'Pacific/Galapagos', name: 'Галапагосские о-ва' },
    { code: 'Pacific/Gambier', name: 'о-ва Гамбье' },
    { code: 'Pacific/Guadalcanal', name: 'Гуадалканал' },
    { code: 'Pacific/Guam', name: 'Гуам' },
    { code: 'Pacific/Honolulu', name: 'Гонолулу' },
    { code: 'Pacific/Johnston', name: 'Джонстон' },
    { code: 'Pacific/Kiritimati', name: 'Киритимати' },
    { code: 'Pacific/Kosrae', name: 'Косрае' },
    { code: 'Pacific/Kwajalein', name: 'Кваджалейн' },
    { code: 'Pacific/Majuro', name: 'Маджуро' },
    { code: 'Pacific/Marquesas', name: 'Маркизские о-ва' },
    { code: 'Pacific/Midway', name: 'о-ва Мидуэй' },
    { code: 'Pacific/Nauru', name: 'Науру' },
    { code: 'Pacific/Niue', name: 'Ниуэ' },
    { code: 'Pacific/Norfolk', name: 'Норфолк' },
    { code: 'Pacific/Noumea', name: 'Нумеа' },
    { code: 'Pacific/Pago_Pago', name: 'Паго-Паго' },
    { code: 'Pacific/Palau', name: 'Палау' },
    { code: 'Pacific/Pitcairn', name: 'Питкэрн' },
    { code: 'Pacific/Ponape', name: 'Понпеи' },
    { code: 'Pacific/Port_Moresby', name: 'Порт-Морсби' },
    { code: 'Pacific/Rarotonga', name: 'Раротонга' },
    { code: 'Pacific/Saipan', name: 'Сайпан' },
    { code: 'Pacific/Tahiti', name: 'Таити' },
    { code: 'Pacific/Tarawa', name: 'Тарава' },
    { code: 'Pacific/Tongatapu', name: 'Тонгатапу' },
    { code: 'Pacific/Truk', name: 'Трук' },
    { code: 'Pacific/Wake', name: 'Уэйк' },
    { code: 'Pacific/Wallis', name: 'Уоллис и Футуна' },
];

export const getUtc = (timeZone: string) => {
    const dayjs = getDayjsWithTimezonePlugin();
    const utcOffset = dayjs().utc().tz(timeZone).utcOffset() / 60;

    const isMoreThanTen = utcOffset >= 10 || utcOffset <= -10;
    const isNegative = utcOffset < 0;
    let code = isNegative ? utcOffset * -1 : utcOffset;
    let digits: string | number = code.toFixed(2).split('.')[1];
    if (Number(digits) > 0) {
        digits = Number(digits) * 0.6;
    }
    code = Number(Math.floor(code).toFixed(0));
    const codeOffset = `${isMoreThanTen ? code : `0${code}`}:${digits}`;
    return isNegative ? `UTC -${codeOffset}` : `UTC +${codeOffset}`;
};
export const getUtcOffset = (timeZone: string) => {
    const dayjs = getDayjsWithTimezonePlugin();
    const utcOffset = dayjs().utc().tz(timeZone).utcOffset() / 60;

    return utcOffset;
};

export { timezones };
