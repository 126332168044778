import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    useSelector,
} from 'react-redux';

import {
    IChannel,
} from 'entities/channels';
import {
    messagesActions,
    TMessage,
    getMessageIdsList,
} from 'entities/message';
import {
    EBehaviorScroll,
} from 'shared/constants/scroll';
import {
    useAppDispatch,
} from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import {
    getQueryVariable,
} from 'shared/lib/utils/validUrl/validUrl';
import {
    IBackendListResponse,
} from 'shared/types/types';

interface IUseScrollMessageProps {
    setQueryParams: Dispatch<SetStateAction<string>>;
    isFetchingMessages: boolean;
    messagesFromServer?: IBackendListResponse<TMessage>;
    channelId: IChannel['id'];
    isAnimation?: boolean;
    handleToggleLoading?: (value: boolean) => void;
}

const ANIMATION_TIME = 300;
const ANIMATION_CLEAR_LOADING_TIME = 600;

export const useScrollMessage = ({
    channelId,
    handleToggleLoading = () => {},
    isAnimation = true,
    isFetchingMessages,
    messagesFromServer,
    setQueryParams,
}: IUseScrollMessageProps) => {
    const dispatch = useAppDispatch();
    const messageIdList = useSelector(getMessageIdsList);

    const [isLoadNewMessages, setIsLoadNewMessages] = useState(false);
    const [scrollMessage, setScrollMessage] = useState(false);
    const [messageId, setMessageId] = useState<string>('');
    const [messagePage, setMessagePage] = useState<string>('');
    const [isMessageListUpdate, setIsMessageListUpdate] = useState(false);
    const [behaviorScrollState, setBehaviorScrollState] = useState<EBehaviorScroll>();

    const clearMessageState = useCallback(() => {
        setMessageId('');
        setMessagePage('');
    }, []);

    const handleExistingMessage = useCallback((
        id: string,
        behaviorScroll: EBehaviorScroll = EBehaviorScroll.SMOOTH,
    ) => {
        dispatch(messagesActions.setBehaviorScrollMessage(behaviorScrollState || behaviorScroll));
        dispatch(messagesActions.setIsAnimationScrollMessage(isAnimation));
        dispatch(messagesActions.setScrollMessageId(id));

        if (isMessageListUpdate) {
            dispatch(messagesActions.setIsMessageListUpdate(true));
            setIsMessageListUpdate(false);
        }

        handleToggleLoading(false);

        setTimeout(() => handleToggleLoading(false), ANIMATION_CLEAR_LOADING_TIME);
    }, [dispatch, isAnimation, isMessageListUpdate, handleToggleLoading]);

    const loadNewMessages = useCallback((
        id: string,
        page: string,
        behaviorScroll: EBehaviorScroll = EBehaviorScroll.SMOOTH,
    ) => {
        const queryVariable = getQueryVariable(page.replace(/.*\?/gi, ''));
        const nextPage = `channel__id=${channelId}&cursor=${queryVariable.cursor}`;

        setMessageId(id);
        setMessagePage(page);
        setQueryParams(nextPage);
        setIsLoadNewMessages(true);
        handleToggleLoading(true);
        setIsMessageListUpdate(true);
        setBehaviorScrollState(behaviorScroll);
    }, [channelId, handleToggleLoading]);

    const handleShowMessage = useCallback((
        id: string,
        page?: string,
        behaviorScroll: EBehaviorScroll = EBehaviorScroll.SMOOTH,
    ) => {
        clearMessageState();

        const isMessageId = messageIdList.find((item) => item === id);
        const isMessageScroll = isMessageId && id;

        if (isMessageScroll) {
            handleExistingMessage(id, behaviorScroll);
            return;
        }

        if (page) {
            loadNewMessages(id, page, behaviorScroll);
        }
    }, [
        messageIdList,
        clearMessageState,
        handleExistingMessage,
        loadNewMessages,
    ]);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (scrollMessage && messageId) {
            timeout = setTimeout(() => {
                handleShowMessage(messageId, messagePage);
                setScrollMessage(false);
            }, ANIMATION_TIME);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [scrollMessage]);

    useEffect(() => {
        const messagesLength = messagesFromServer && messagesFromServer?.results?.length;

        if (isLoadNewMessages && messagesLength && !isFetchingMessages) {
            dispatch(messagesActions.setMessages(messagesFromServer?.results));
            dispatch(messagesActions.updateNextUrlMessage(messagesFromServer.next));
            dispatch(messagesActions.updatePreviousUrlMessage(messagesFromServer.previous));

            setIsLoadNewMessages(false);
            handleToggleLoading(false);
            setScrollMessage(true);
        }
    }, [isLoadNewMessages, isFetchingMessages, messagesFromServer]);

    return { handleShowMessage };
};
