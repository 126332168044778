export interface IBackendListResponse<T> {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

export enum EErrors {
    FETCH_ERROR = 'FETCH_ERROR',
}
export interface IInternetConnectionError {
    status: EErrors;
    error: string;
}

export type TNotificationType = 'success' | 'info' | 'warning' | 'error';

export type DeepPartial<T> = T extends object ? {
    [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export type HeadingType = 'section' | 'div' | 'p' | 'span';
