import {
    IStaticStickerMessage,
    IVideoStickerMessage,
} from '../model/types/message.types';

export const DEFAULT_STICKER_WIDTH = 220;

export const getStickersSizes = (message: IStaticStickerMessage | IVideoStickerMessage) => {
    const { stickerHeight, stickerWidth } = message.post;
    const notDefinedValues = !stickerHeight || !stickerWidth;

    if (notDefinedValues) {
        return { height: '' };
    }

    const ratio = stickerHeight / stickerWidth;
    const heightBasedOnRatio = (ratio > 1 ? `${DEFAULT_STICKER_WIDTH}px` : `${ratio * DEFAULT_STICKER_WIDTH}px`);
    const height = stickerHeight ? heightBasedOnRatio : '';

    return { height };
};
