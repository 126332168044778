import {
    FC,
    memo,
} from 'react';

import {
    EPostTypeMessage,
    TMessage,
} from '../../model/types/message.types';
import { HolderFileLoader } from '../holder-file-loader/HolderFileLoader';
import { AnimationStickerMessage } from '../variants/animation-sticker/AnimationStickerMessage';
import { AudioMessage } from '../variants/audio-message/AudioMessage';
import { RoundVideo } from '../variants/round-video/RoundVideo';
import { StaticSticker } from '../variants/static-sticker/StaticSticker';
import { VideoSticker } from '../variants/video-sticker/VideoSticker';
import { VoiceMessage } from '../variants/voice-message/VoiceMessage';

import cls from './ContentTypeRenderer.module.scss';

interface IContentTypeRendererProps {
    message: TMessage;
    timezone?: string;
}

export const ContentTypeRenderer: FC<IContentTypeRendererProps> = memo((props) => {
    const { message, timezone } = props;
    const postFile = message?.post?.file;
    const type = message.postType;

    return (
        <>
            {type === EPostTypeMessage.VOICE_MESSAGE && postFile
            && (
                <HolderFileLoader file={postFile}>
                    <div className={cls.wrapperAudio}>
                        <VoiceMessage
                            file={postFile}
                            messageId={message.id}
                            date={message.publishAt}
                            timezone={timezone}
                        />
                    </div>
                </HolderFileLoader>
            )}
            {type === EPostTypeMessage.AUDIO_MESSAGE && postFile
            && (
                <HolderFileLoader file={postFile}>
                    <div className={cls.wrapperAudio}>
                        <AudioMessage
                            file={postFile}
                            messageId={message.id}
                            date={message.publishAt}
                            timezone={timezone}
                        />
                    </div>
                </HolderFileLoader>
            )}
            {type === EPostTypeMessage.VIDEO_STICKER && postFile && <VideoSticker message={message} file={postFile} />}
            {type === EPostTypeMessage.STATIC_STICKER && postFile && <StaticSticker message={message} file={postFile} />}
            {type === EPostTypeMessage.ANIMATED_STICKER && postFile && <AnimationStickerMessage file={postFile} />}
            {type === EPostTypeMessage.ROUND_VIDEO && postFile && <RoundVideo file={postFile} messageId={message.id} />}
        </>
    );
});
