import {
    FC,
    memo,
    useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
    Link,
} from 'react-router-dom';

import {
    Button,
} from 'antd';

import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import {
    IChannel,
    channelActions,
} from 'entities/channels';
import {
    getMessagesShouldUpdate,
    messagesActions,
} from 'entities/message';
import { getStateIsChannelEdition } from 'entities/user';
import { ImportMessages } from 'features/import-messages';
import { EDIT_CHANNEL_URL } from 'shared/constants/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { MiniHeader } from 'shared/ui/mini-header/MiniHeader';

import cls from './ChannelPageHeader.module.scss';

interface IChannelPageHeaderProps {
    className?: string;
    channel?: IChannel;
    channelId: string;
    isLoading: boolean;
    channelName: string;
}

export const ChannelPageHeader: FC<IChannelPageHeaderProps> = memo((props) => {
    const {
        channel,
        channelId,
        channelName,
        className,
        isLoading,
    } = props;

    const showUpdatesPost = false; // TODO: добавить функционал на отображение линии прогресса импрота

    const messageUpdatingStatus = useSelector(getMessagesShouldUpdate);

    const dispatch = useAppDispatch();

    const isChannelEditionRight = useSelector(getStateIsChannelEdition);

    const openEditChannelPage = () => {
        dispatch(messagesActions.updateOpenFromChannelList(false));
    };

    const handleOpenCreateModal = useCallback(() => {
        dispatch(channelActions.setOpenCreateMessageModal(true));
    }, []);

    return (
        <MiniHeader className={classNames(cls.channelPageHeader, {}, [className])}>
            <div className={cls.left}>
                <p className={cls.title}>{channelName}</p>
                <div className={cls.blockHidingText} />
            </div>

            <div className={cls.right}>
                {channel?.donorInviteUrl && <ImportMessages channel={channel} isDisabled={isLoading} />}
                <Button
                    icon={(
                        <PlusCircleOutlined />
                    )}
                    disabled={messageUpdatingStatus === 'pending'}
                    onClick={handleOpenCreateModal}
                />
                {isChannelEditionRight && (
                    <Link to={`${EDIT_CHANNEL_URL}/${channelId}`} onClick={openEditChannelPage}>
                        <Button
                            icon={(
                                <SettingOutlined />
                            )}
                        />
                    </Link>
                )}
            </div>

            {showUpdatesPost && (
                <div className={cls.holerLine}>
                    <div className={cls.line} style={{ width: '50%' }} />
                </div>
            )}
        </MiniHeader>
    );
});
