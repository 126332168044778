import {
    FC,
    memo,
} from 'react';

import { IChannel } from 'entities/channels';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';

import { ChannelPageHeader } from './variants/channel-page-header/ChannelPageHeader';
import { ChannelPageMobileHeader } from './variants/channel-page-mobile-header/ChannelPageMobileHeader';

interface IChannelPageHeaderProps {
    className?: string;
    channel?: IChannel;
    channelId: string;
    isLoading: boolean;
    channelName: string;
    handleOpenNotification: () => void;
}

export const ChannelPageHeaderWrapper: FC<IChannelPageHeaderProps> = memo((props) => {
    const {
        channel,
        channelId,
        channelName,
        className,
        handleOpenNotification,
        isLoading,
    } = props;

    const {
        isTablet,
    } = useScreenType();

    if (isTablet) {
        return (
            <ChannelPageMobileHeader
                channelName={channelName}
                channel={channel}
                channelId={channelId}
                isLoading={isLoading}
                handleOpenNotification={handleOpenNotification}
            />
        );
    }

    return (
        <ChannelPageHeader
            channel={channel}
            channelId={channelId}
            channelName={channelName}
            className={className}
            isLoading={isLoading}
        />
    );
});
