import { TMessage } from '../model/types/message.types';

export const sortMessageList = (array: TMessage[]) => {
    if (!array || array.length === 0) {
        return [];
    }

    return [...array].sort((messageOne, messageTwo) => {
        const firstPublishAt = String(messageOne.publishAt);
        const secondPublishAt = String(messageTwo.publishAt);
        const res = firstPublishAt.localeCompare(secondPublishAt);

        if (res !== 0) {
            return res;
        }

        if (messageOne.donorMessageId && messageTwo.donorMessageId) {
            return messageOne.donorMessageId - messageTwo.donorMessageId;
        }

        const firstCreatedAt = String(messageOne.createdAt);
        const secondCreatedAt = String(messageTwo.createdAt);

        return firstCreatedAt.localeCompare(secondCreatedAt);
    });
};
