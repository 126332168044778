import {
    FC,
    memo,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    Link,
} from 'react-router-dom';

import {
    Dropdown,
    MenuProps,
} from 'antd';

import FormOutlined from '@ant-design/icons/FormOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import {
    ChannelAvatar,
    IChannel,
    channelActions,
} from 'entities/channels';
import {
    messagesActions,
    getMessagesShouldUpdate,
} from 'entities/message';
import {
    getStateIsChannelEdition,
    getStateIsEditPost,
} from 'entities/user';
import { ImportMessages } from 'features/import-messages';
import {
    EDIT_CHANNEL_URL,
    HOME_URL,
} from 'shared/constants/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { DynamicHeader } from 'shared/ui/dynamic-header/DynamicHeader';
import { Text } from 'shared/ui/text/Text';
import { createMessageActions } from 'widgets/create-message';

import cls from './ChannelPageMobileHeader.module.scss';

interface IChannelPageMobileHeaderProps {
    channel?: IChannel;
    channelId: string;
    isLoading: boolean;
    channelName: string;
    handleOpenNotification: () => void;
}

export const ChannelPageMobileHeader: FC<IChannelPageMobileHeaderProps> = memo((props) => {
    const {
        channel, channelId, channelName, handleOpenNotification, isLoading,
    } = props;

    const messageUpdatingStatus = useSelector(getMessagesShouldUpdate);

    const dispatch = useAppDispatch();

    const isChannelEditionRight = useSelector(getStateIsChannelEdition);
    const isEditPostRight = useSelector(getStateIsEditPost);

    const [isOpenDropdown, setIsOpenDropdown] = useState(false);

    const messageUpdatingStatusPending = messageUpdatingStatus === 'pending';

    const openEditChannelPage = () => {
        dispatch(messagesActions.updateOpenFromChannelList(false));
    };

    const handleOpenCreateModal = useCallback(() => {
        dispatch(createMessageActions.clearText());
        dispatch(channelActions.setOpenCreateMessageModal(true));
    }, []);

    const items: MenuProps['items'] = useMemo(
        () => [
            ...(isEditPostRight && channel?.donorInviteUrl ? [{
                key: 'Update',
                label: <ImportMessages channel={channel} isDisabled={isLoading} />,
            }] : []),
            {
                key: 'createPost',
                label: (
                    <button
                        type="button"
                        className={cls.holderDropdownItem}
                        onClick={handleOpenCreateModal}
                        disabled={messageUpdatingStatusPending}
                    >
                        <div className={cls.dropdownItem}>
                            <Text text="Создать пост" theme="black" />
                            <PlusCircleOutlined className={cls.itemIcon} />
                        </div>
                    </button>
                ),
            },
            ...(isChannelEditionRight ? [{
                key: 'edit',
                label: (
                    <Link to={`${EDIT_CHANNEL_URL}/${channelId}`} onClick={openEditChannelPage}>
                        <button type="button" className={cls.holderDropdownItem}>
                            <div className={cls.dropdownItem}>
                                <Text text="Редактировать канал" theme="black" />
                                <FormOutlined className={cls.itemIcon} />
                            </div>
                        </button>
                    </Link>
                ),
            }] : []),
            {
                key: 'notifications',
                label: (
                    <button type="button" className={cls.holderDropdownItem} onClick={handleOpenNotification}>
                        <div className={cls.dropdownItem}>
                            <Text text="Центр уведомлений" theme="black" />
                            <InfoCircleOutlined className={cls.itemIcon} />
                        </div>
                    </button>
                ),
            },
        ],
        [channel, channelId, isLoading, isChannelEditionRight, isEditPostRight, messageUpdatingStatus],
    );

    const Avatar = useMemo(() => {
        if (!channel || isLoading) {
            return null;
        }

        return (
            <>
                <Dropdown
                    menu={{ items }}
                    placement="bottomRight"
                    overlayClassName={cls.holderDropdown}
                    onOpenChange={(value) => setIsOpenDropdown(value)}
                    disabled={messageUpdatingStatusPending}
                >
                    <div>
                        <ChannelAvatar
                            channel={channel}
                            className={classNames(
                                cls.channelAvatar,
                                { [cls.disabledAvatar]: messageUpdatingStatusPending },
                            )}
                        />
                    </div>
                </Dropdown>
                {/* we display this block in a hidden position,
                since the dropdown does not render the item
                when it is closed, and we have logic in the
                component that should work */}
                {!isOpenDropdown && <ImportMessages channel={channel} isDisabled={isLoading} className={cls.hideButtonImport} />}
            </>
        );
    }, [channel, items, isOpenDropdown, isLoading]);

    return (
        <DynamicHeader
            leftBlock={(
                <Link to={HOME_URL}>
                    <LeftOutlined className={cls.iconBack} />
                </Link>
            )}
            title={channelName}
            rightBlock={Avatar}
            isHideCenterBlock={!channelName || isLoading}
        />
    );
});
