import {
    Dispatch,
    RefObject,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import { getIsAnimationScrollMessage } from 'entities/message';

interface IUsePinMessageAnimationProps {
    holderRef: RefObject<HTMLImageElement>;
    idPinMessage: string | null;
    id: number | string;
    setScrollMessageId: Dispatch<SetStateAction<number | null>>;
}

const ANIMATION_TIME = 1500;

export const usePinMessageAnimation = ({
    holderRef,
    id,
    idPinMessage,
    setScrollMessageId,
}: IUsePinMessageAnimationProps): boolean => {
    const [isAnimation, seIisAnimation] = useState(false);
    const isAnimationScrollMessage = useSelector(getIsAnimationScrollMessage);

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setScrollMessageId(null);

                if (isAnimationScrollMessage) {
                    seIisAnimation(true);
                    setTimeout(() => {
                        seIisAnimation(false);
                    }, ANIMATION_TIME);
                }
            }
        });
    });

    useEffect(() => {
        if (idPinMessage === id && holderRef.current) observer.observe(holderRef.current);
        return () => {
            if (holderRef.current) observer.unobserve(holderRef.current);
        };
    }, [id, idPinMessage]);

    return isAnimation;
};
