import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    Modal,
} from 'antd';
import { Dayjs } from 'dayjs';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import {
    IChannel,
    channelActions,
    getSelectChannelName,
} from 'entities/channels';
import {
    EMediaMessageTypes,
    EPostTypeMessage,
    TMessage,
    getErrorUpdateMessage,
    getIsOpenFromChannelList,
    getMessagesShouldUpdate,
    messagesActions,
    useRemoveMessageMutation,
} from 'entities/message';
import {
    getNotificationsFromSlice,
    useGetNotificationsQuery,
} from 'entities/notification';
import { EHintUpdatePost } from 'features/import-messages';
import {
    ETextMaxSymbolsLength,
    deserialize,
} from 'features/rich-text-editor';
import { HOME_URL } from 'shared/constants/router';
import { EBehaviorScroll } from 'shared/constants/scroll';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useNotification } from 'shared/lib/hooks/useNotification/useNotification';
import { useScrollMessage } from 'shared/lib/hooks/useScrollMessage/useScrollMessage';
import { IBackendListResponse } from 'shared/types/types';
import { editMessageActions } from 'widgets/edit-message';

const SHOW_LAST_PUBLISHED_POST_TIME = 100;

interface IUseChannelHandlerProps {
    channel?: IChannel;
    channelId: string;
    isFetchingMessages: boolean;
    isLoadingChannel: boolean;
    messagesFromServer?: IBackendListResponse<TMessage>;
    refetch: () => void;
    reloadChannels: () => void;
    setQueryParams: Dispatch<SetStateAction<string>>;
    statusQueryChannel: QueryStatus;
    statusQueryMessages: QueryStatus;
    prevChannelId: string;
}

interface IUseChannelHandlerReturn {
    channelName: string;
    handleClearLastPublishedLoad: (value: boolean) => void;
    handleEditMessage: (mes: TMessage) => void;
    handleOpenNotification: () => void;
    handleReloadChannels: () => void;
    handleRemoveMessage: (messageId: string) => void;
    isLoadingChangeChannel: boolean;
    isOpenNotifications: boolean;
    isUpdateNotifications: boolean;
    onScrollMessage: () => void;
    open: boolean;
    reloadMessages: () => void;
    setIsOpenNotifications: Dispatch<SetStateAction<boolean>>;
    setIsUpdateChannels: Dispatch<SetStateAction<boolean>>;
    setShouldAddMessages: Dispatch<SetStateAction<boolean>>;
    setUpdateNotifications: Dispatch<SetStateAction<boolean>>;
    shouldAddMessages: boolean;
    toggleModal: () => void;
    isNotifications: boolean;
    statusUpdateChannel: EHintUpdatePost | null ;
}

export const useChannelHandler = (props: IUseChannelHandlerProps): IUseChannelHandlerReturn => {
    const {
        channel,
        channelId,
        isFetchingMessages,
        isLoadingChannel,
        messagesFromServer,
        prevChannelId,
        refetch,
        reloadChannels,
        setQueryParams,
        statusQueryChannel,
        statusQueryMessages,
    } = props;

    const { openNotification } = useNotification();

    const [open, setOpenModalToChangeMessage] = useState(false);
    const [shouldAddMessages, setShouldAddMessages] = useState(false);
    const [isHideMessageList, setIsHideMessageList] = useState(false);
    const [channelName, setChannelName] = useState('');
    const [isUpdateChannels, setIsUpdateChannels] = useState(false);
    const [isOpenNotifications, setIsOpenNotifications] = useState(false);
    const [isUpdateNotifications, setUpdateNotifications] = useState(false);
    const [isScrollMessage, setIsScrollMessage] = useState(false);
    const [isCheckLastPublishedPost, setIsCheckLastPublishedPost] = useState(false);
    const [isLastPublishedPost, setIsLastPublishedPost] = useState(false);
    const [postIdDeleting, setPostIdDeleting] = useState('');

    const selectChannelName = useSelector(getSelectChannelName);
    const messageUpdatingStatus = useSelector(getMessagesShouldUpdate);
    const isOpenFromChannelList = useSelector(getIsOpenFromChannelList);
    const notifications = useSelector(getNotificationsFromSlice.selectAll);
    const errorUpdateMessage = useSelector(getErrorUpdateMessage);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [removeMessage, {
        isError: isRemovedError,
        isLoading: isRemovedLoading,
        isSuccess: isRemovedSuccess,
    }] = useRemoveMessageMutation();
    const { data: notificationsFromServer, refetch: refetchNotifications } = useGetNotificationsQuery({
        channelId,
    });

    const handleClearLastPublishedLoad = (value: boolean) => {
        setIsLastPublishedPost(value);
        setIsCheckLastPublishedPost(value);
    };

    const { handleShowMessage } = useScrollMessage({
        channelId,
        handleToggleLoading: handleClearLastPublishedLoad,
        isAnimation: false,
        isFetchingMessages,
        messagesFromServer,
        setQueryParams,
    });

    const statusUpdateChannel = useMemo(() => {
        if (messageUpdatingStatus === QueryStatus.pending) {
            return EHintUpdatePost.UPDATED;
        }

        if (errorUpdateMessage) {
            return EHintUpdatePost.ERROR;
        }

        return null;
    }, [messageUpdatingStatus, errorUpdateMessage]);

    const isLoadingChangeChannel = useMemo(
        () => isLastPublishedPost
            || isCheckLastPublishedPost
            || isHideMessageList
            || channelId !== prevChannelId
            || isLoadingChannel
            || (!isUpdateChannels && statusQueryChannel === QueryStatus.pending)
            || !notificationsFromServer
            || (notificationsFromServer.results.length > 0 && notifications.length === 0 && isOpenFromChannelList),
        [
            isCheckLastPublishedPost,
            isHideMessageList,
            channelId,
            prevChannelId,
            isLoadingChannel,
            statusQueryChannel,
            isUpdateChannels,
            isLastPublishedPost,
            notificationsFromServer,
            notifications,
            isOpenFromChannelList,
        ],
    );

    const isNotifications = useMemo(() => notifications.length > 0, [notifications]);

    const onScrollMessage = () => {
        reloadChannels();
        refetch();
        setIsScrollMessage(true);
    };

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;

        const hasScrollLastPublishedPost = isLastPublishedPost
        && !isLoadingChannel
        && channel
        && statusQueryChannel !== QueryStatus.pending;

        if (hasScrollLastPublishedPost) {
            timeout = setTimeout(() => {
                handleShowMessage(
                    channel.lastPublishedPostId,
                    channel.lastPublishedPostPageUrl,
                    EBehaviorScroll.AUTO,
                );
            }, SHOW_LAST_PUBLISHED_POST_TIME);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isLastPublishedPost, isLoadingChannel, channel, statusQueryChannel]);

    useEffect(() => {
        const hasScrollLastPublishedPost = isScrollMessage
        && !isLoadingChannel
        && channel
        && statusQueryChannel !== QueryStatus.pending;

        if (hasScrollLastPublishedPost) {
            handleShowMessage(channel.lastPublishedPostId, channel.lastPublishedPostPageUrl);
            setIsScrollMessage(false);
        }
    }, [isScrollMessage, isLoadingChannel, channel, statusQueryChannel]);

    const handleReloadChannels = useCallback(() => {
        reloadChannels();
        dispatch(messagesActions.setIsMessageListUpdate(true));
    }, [reloadChannels]);

    const toggleModal = useCallback(() => {
        setOpenModalToChangeMessage((prev) => !prev);
    }, []);

    const reloadMessages = useCallback(() => {
        setUpdateNotifications(true);
        refetchNotifications();
        refetch();
    }, []);

    const handleEditMessage = useCallback(
        (mes: TMessage) => {
            let newEditorState = null;

            const isDocs = mes.post?.media?.some((el) => el.type === EMediaMessageTypes.DOCUMENT);
            const isMedia = mes.post?.media?.some(
                (el) => el.type === EMediaMessageTypes.PHOTO || el.type === EMediaMessageTypes.VIDEO,
            );
            const isRoundVideo = mes.postType === EPostTypeMessage.ROUND_VIDEO && !!mes.post?.file;
            const isVoice = mes.postType === EPostTypeMessage.VOICE_MESSAGE && !!mes.post?.file;
            const isAudio = mes.postType === EPostTypeMessage.AUDIO_MESSAGE && !!mes.post?.file;

            const isMediaLengthEqualsOneAndWebPreviewEnabled = mes.webPreviewLimitsBypassEnabled
            && mes.post?.media?.length === 1;

            const isMax4094SymbolsLength = mes.postType === EPostTypeMessage.TEXT || isMediaLengthEqualsOneAndWebPreviewEnabled;
            const maxSymbolsLength = isMax4094SymbolsLength
                ? ETextMaxSymbolsLength.LONG_LENGTH
                : ETextMaxSymbolsLength.SHORT_LENGTH;

            const file = mes.post.file ? { ...mes.post.file, uid: mes.post.file.id } : null;
            const media = mes?.post?.media?.map((el) => ({ ...el.file, type: el.type, uid: el.file.id })) || [];
            if (mes.text || isMedia) {
                newEditorState = deserialize(mes?.text || '');
            }

            dispatch(
                editMessageActions.setData({
                    audio: isAudio && file ? file : null,
                    buttons: mes?.buttons || [],
                    date: mes.publishAt as Dayjs,
                    dateValidType: '',
                    documents: isDocs && media ? media : null,
                    isNotifyPin: mes.isNotifyPin,
                    isPinIndefinitely: mes.isPinIndefinitely,
                    isWebPreview: mes.webPreviewLimitsBypassEnabled,
                    maxSymbolsLength,
                    media: isMedia && media ? media : null,
                    message: mes,
                    pinDuration: mes.pinDuration,
                    postType: mes.postType,
                    roundVideo: isRoundVideo && file ? file : null,
                    text: newEditorState,
                    time: mes.publishAt as Dayjs,
                    timezone: channel?.timezone ?? '',
                    variables: channel?.variables,
                    voice: isVoice && file ? file : null,
                }),
            );

            toggleModal();
        },
        [channel],
    );

    const handleRemoveMessage = useCallback((messageId: string) => {
        removeMessage({ messageId });
        setPostIdDeleting(messageId);
        setUpdateNotifications(true);
    }, []);

    const handleOpenNotification = useCallback(() => {
        setIsOpenNotifications(true);
    }, []);

    useEffect(() => {
        if (!channel && !isLoadingChannel) {
            navigate(HOME_URL);
        }
    }, [channel, isLoadingChannel]);

    useEffect(() => {
        if (statusQueryMessages === QueryStatus.pending) {
            setIsHideMessageList(true);
        }
        Modal.destroyAll();
        setOpenModalToChangeMessage(false);
    }, [channelId]);

    useEffect(() => {
        if (statusQueryMessages === QueryStatus.fulfilled) {
            setIsHideMessageList(false);
        }
    }, [statusQueryMessages]);

    useEffect(() => {
        if (statusQueryChannel === QueryStatus.fulfilled && isUpdateChannels) {
            setIsUpdateChannels(false);
        }
    }, [statusQueryChannel]);

    useEffect(() => {
        if (selectChannelName) {
            setChannelName(selectChannelName);
            dispatch(channelActions.setSelectChannelName(''));
        }

        if (channel && channel.name !== channelName) {
            setChannelName(channel.name);
        }
    }, [selectChannelName, channel]);

    useEffect(() => {
        if (channelId !== prevChannelId) {
            setIsCheckLastPublishedPost(true);
        }
    }, [channelId, prevChannelId]);

    useEffect(() => {
        refetchNotifications();
    }, [channelId]);

    useEffect(() => {
        if (postIdDeleting && isRemovedSuccess && !isRemovedLoading) {
            openNotification({
                description: 'Пост удален',
                icon: <DeleteOutlined />,
                type: 'info',
            });

            dispatch(messagesActions.deleteMessage(postIdDeleting));
            setPostIdDeleting('');
        }
    }, [isRemovedSuccess, isRemovedLoading, postIdDeleting]);

    useEffect(() => {
        if (postIdDeleting && isRemovedError && !isRemovedLoading) {
            setPostIdDeleting('');
        }
    }, [isRemovedError, isRemovedLoading, postIdDeleting]);

    return {
        channelName,
        handleClearLastPublishedLoad,
        handleEditMessage,
        handleOpenNotification,
        handleReloadChannels,
        handleRemoveMessage,
        isLoadingChangeChannel,
        isNotifications,
        isOpenNotifications,
        isUpdateNotifications,
        onScrollMessage,
        open,
        reloadMessages,
        setIsOpenNotifications,
        setIsUpdateChannels,
        setShouldAddMessages,
        setUpdateNotifications,
        shouldAddMessages,
        statusUpdateChannel,
        toggleModal,
    };
};
