import {
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { IChannel } from 'entities/channels';
import {
    TMessage,
    getErrorUpdateMessage,
    getIsOpenFromChannelList,
} from 'entities/message';
import {
    NotificationList,
    NotificationPublicationBlockInfo,
    useGetNotificationsQuery,
    getNotificationsFromSlice,
} from 'entities/notification';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { useScrollMessage } from 'shared/lib/hooks/useScrollMessage/useScrollMessage';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { IBackendListResponse } from 'shared/types/types';
import { DynamicHeader } from 'shared/ui/dynamic-header/DynamicHeader';
import { FullscreenContainer } from 'shared/ui/fullscreen-container/FullscreenContainer';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';
import { Text } from 'shared/ui/text/Text';
import { WrappedIcon } from 'shared/ui/wrapped-icon/WrappedIcon';

import cls from './NotificationSidebar.module.scss';

interface INotificationSidebarProps {
    channelId: IChannel['id'];
    isFetchingMessages: boolean;
    messagesFromServer?: IBackendListResponse<TMessage>;
    setQueryParams: Dispatch<SetStateAction<string>>;
    isLoadingChangeChannel: boolean;
    channelName: string;
    isOpenNotifications: boolean;
    setIsOpenNotifications: Dispatch<SetStateAction<boolean>>;
    isUpdateNotifications: boolean;
    setUpdateNotifications: Dispatch<SetStateAction<boolean>>;
}

export const NotificationSidebar: FC<INotificationSidebarProps> = (props) => {
    const {
        channelId,
        channelName,
        isFetchingMessages,
        isLoadingChangeChannel,
        isOpenNotifications,
        isUpdateNotifications,
        messagesFromServer,
        setIsOpenNotifications,
        setQueryParams,
        setUpdateNotifications,
    } = props;

    const notifications = useSelector(getNotificationsFromSlice.selectAll);
    const isOpenFromChannelList = useSelector(getIsOpenFromChannelList);
    const errorUpdateMessage = useSelector(getErrorUpdateMessage);

    const [queryCursor, setQueryCursor] = useState('');
    const [emptyNotifications, setEmptyNotifications] = useState(true);

    const { isTablet } = useScreenType();
    const {
        data: notificationFromServer, isFetching, isLoading, refetch,
    } = useGetNotificationsQuery({
        channelId,
        cursor: queryCursor,
    });

    const { handleShowMessage } = useScrollMessage({
        channelId,
        isFetchingMessages,
        messagesFromServer,
        setQueryParams,
    });

    const isPublicationBlock = notifications?.some(({ publicationBlock }) => !!publicationBlock) || false;

    const handleCloseNotificationsModal = () => {
        setIsOpenNotifications(false);
    };

    const NotificationContent = (
        <NotificationList
            notificationFromServer={notificationFromServer}
            channelId={channelId}
            isFetching={isFetching}
            isLoading={isLoading}
            queryCursor={queryCursor}
            setQueryCursor={setQueryCursor}
            setEmptyNotifications={setEmptyNotifications}
            emptyNotifications={emptyNotifications}
            handleShowMessage={handleShowMessage}
            handleCloseNotificationsModal={handleCloseNotificationsModal}
        />
    );

    const PublicationBlockInfo = !emptyNotifications && isPublicationBlock
        ? <NotificationPublicationBlockInfo />
        : null;

    useEffect(() => {
        if (isUpdateNotifications) {
            setUpdateNotifications(false);

            if (queryCursor) {
                setQueryCursor('');
            } else {
                refetch();
            }
        }
    }, [isUpdateNotifications]);

    useEffect(() => {
        refetch();
        setIsOpenNotifications(false);
    }, [channelId]);

    useEffect(() => {
        const isCountNotificationsMoreThanZero = (notificationFromServer?.results?.length ?? 0) > 0;
        const isNotLoadingOrFetchingNotifications = !isFetching && !isLoading;
        let timeout: ReturnType<typeof setTimeout>;

        const shouldOpenNotifications = isCountNotificationsMoreThanZero
         && isNotLoadingOrFetchingNotifications
         && isOpenFromChannelList
         && !errorUpdateMessage;

        if (shouldOpenNotifications) {
            timeout = setTimeout(() => {
                setIsOpenNotifications(true);
            }, 0);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [notificationFromServer, isFetching, isLoading, isOpenFromChannelList]);

    if (isTablet && isOpenNotifications) {
        return (
            <FullscreenContainer className={cls.containerTablet}>
                <DynamicHeader
                    leftBlock={(
                        <WrappedIcon
                            Icon={LeftOutlined}
                            onClick={handleCloseNotificationsModal}
                            className={cls.backIcon}
                        />
                    )}
                    centerBlock={(
                        <Text
                            className={cls.headerTabletText}
                            text={(
                                <>
                                    <Text
                                        text="Центр уведомлений"
                                        size="md"
                                        weight="600"
                                        className={cls.headerTabletTitle}
                                    />
                                    <Text text={channelName} className={cls.channelName} />
                                </>
                            )}
                        />
                    )}
                />
                <div className={classNames(cls.content, { [cls.contentWithBlockInfo]: isPublicationBlock })}>
                    {PublicationBlockInfo}
                    {NotificationContent}
                    <PageLoader isLoading={isLoading} withoutPortal isAbsolute />
                </div>
            </FullscreenContainer>
        );
    }

    if (isTablet && !isOpenNotifications) {
        return null;
    }

    return (
        <div
            className={classNames(cls.container, {
                [cls.hideContainer]: emptyNotifications || isLoadingChangeChannel || notifications.length === 0,
            })}
        >
            <div className={cls.header}>
                <Text text="Уведомления" weight="600" as="h3" isTitle size="md" />
            </div>
            <div className={cls.content}>
                {NotificationContent}
                {PublicationBlockInfo}
                <PageLoader isLoading={isLoading} withoutPortal isAbsolute />
            </div>
        </div>
    );
};
