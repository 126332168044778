import { api } from 'shared/config/rtk-query-api/api.services';
import { IBackendListResponse } from 'shared/types/types';

import {
    transformGetMessage,
    transformGetMessages,
} from '../../adapters/serverData';
import {
    TMessage,
    IResponseResendPost,
} from '../types/message.types';

interface IGetMessagesArgs {
    channelId: string;
    query: string;
    sessionId: number;
}

interface IGetCheckPostPublishStatusArgs {
    id: string;
}

interface IResendPostArgs {
    id: string;
}

const messagesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        checkPostPublishStatus: builder.query<void, IGetCheckPostPublishStatusArgs>({
            query: ({ id }) => ({ method: 'GET', url: `/api/v1/posts/${id}/resend/` }),
        }),
        getMessage: builder.query<TMessage, { messageId: string }>({
            query: ({ messageId }) => ({
                method: 'GET',
                url: `/api/v1/posts/${messageId}/`,
            }),
            transformResponse: transformGetMessage,
        }),
        getMessages: builder.query<IBackendListResponse<TMessage>, IGetMessagesArgs>({
            providesTags: () => [{ type: 'Messages' }],
            query: ({ channelId, query }) => ({ url: `/api/v1/posts/?${query || `channel__id=${channelId}`}` }),
            transformResponse: transformGetMessages,
        }),
        removeMessage: builder.mutation<void, { messageId: string }>({
            query: ({ messageId }) => ({
                method: 'DELETE',
                url: `/api/v1/posts/${messageId}/`,
            }),
        }),
        resendPost: builder.mutation<IResponseResendPost, IResendPostArgs>({
            query: ({ id }) => ({ method: 'POST', url: `/api/v1/posts/${id}/resend/` }),
        }),
    }),
});

export const {
    useGetMessagesQuery,
    useLazyCheckPostPublishStatusQuery,
    useLazyGetMessageQuery,
    useRemoveMessageMutation,
    useResendPostMutation,
} = messagesApi;
